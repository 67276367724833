import React from "react"
import Header from "../components/header"
import { Container } from "react-bootstrap"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import ym from "react-yandex-metrika"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Link } from "gatsby"

class Cooperation extends React.Component {

  componentDidMount() {
    ym("hit", "/products")
  }

  render() {
    let description = "ИСПОЛИНИЯ приглашает к сотрудничеству компании, заинтересованные в работе с продукцией RBM."
    return (
      <div>
        <Helmet>
          <title>Сотрудничество - Исполиния</title>
          <meta name="description"
                content={description} />
          <meta property="og:title" content="Производство" />
          <meta property="og:description"
                content={description} />
        </Helmet>
        <Header />
        <main>

          <Container className="mb-5">
            <div className="row m-auto">
              <p>Компания <span>ИСПОЛИНИЯ</span> заинтересована в расширении партнерской сети и выстраивает долгосрочные
                деловые
                отношения на взаимовыгодной основе. Мы приглашаем к сотрудничеству профессионалов отрасли и с
                удовольствием подскажем оптимальный именно для Вас вариант работы с ассортиментом RBM.
                <br />
                Мы способны заинтересовать:
              </p>

              <h3>Предприятия торговли</h3>
              <p>Поскольку товары RBM представлены на российском рынке более 20 лет, и за этот продолжительный срок
                они успели занять свои позиции в специализированной рознице прежде всего в таких категориях, как
                фильтры линейные и самопромывные, а также водопроводная арматура.</p>
              <h3>Строительные компании</h3>
              <p>Накоплен опыт поставок оборудования RBM на объекты разного профиля от небольшого частного
                домостроения до высотных объектов. Наиболее значимыми оказались поставки редукторов давления,
                воздухоотводчиков и радиаторных клапанов в новое многоэтажное строительство. Перечень востребованных в
                данном сегменте товаров постоянно расширяется.</p>
              <h3>Профессиональные монтажники</h3>
              <p>Самые свежие и впечатляющие достижения RBM относятся к категории магнитных фильтров. Здесь компания
                является признанным европейским лидером отрасли, а также инноватором. В России магнитная фильтрация
                пребывает на ранней стадии развития, но с учетом динамики рынка котельного оборудования представляется
                очень перспективной. И именно профессиональные установщики котельного оборудования призваны стать
                локомотивом развития данного сегмента. Подробнее о магнитной фильтрации смотрите в разделе <Link
                  to="/products">ПРОДУКЦИЯ</Link>.</p>
              <h3>Проектные учреждения</h3>
              <p>Успех любого бренда на инженерном рынке невозможен без регулярного присутствия его компонентов в
                технической документации проектируемых объектов. Компания серьезно относится к данному направлению,
                способному создать надежный задел для будущих продаж.</p>
              <h3>Промышленные предприятия</h3>
              <p>В ассортименте RBM представлены товары, которые востребованы в промышленности как для собственных
                хозяйственных нужд, так и в качестве комплектующих, необходимых в производственном процессе. Чаще
                всего это фильтры и редукторы давления больших диаметров.
              </p>
              <h3>Конечные пользователи</h3>
              <p>Мы откликаемся на каждое попавшее к нам обращение, касающееся RBM, даже если Ваша профессиональная
                деятельность не связана с инженерной продукцией. Если Вы столкнулись с компонентами RBM частным
                образом в своем жилище, и возникли вопросы… Просто адресуйте их нам. Мы сделаем всё необходимое, чтобы
                быть полезными.</p>
              <p>Пожалуйста, свяжитесь с нами любым доступным способом, и мы оперативно Вам ответим и предоставим
                профильное предложение.
              </p>
              <span>Выражаем надежду на долгосрочное сотрудничество.</span>
            </div>
          </Container>
        </main>
        <Footer />

      </div>
    )
  }
}

export default Cooperation